import { Input, NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import { useFormik } from "formik";
import BuzopsButton from "generic-components/BuzopsButton";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Error } from "@progress/kendo-react-labels";
import ClientSelect from "atoms/ClientSelect/ClientSelect";
import { ClassService } from "services/class/index.service";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CurrencyFormat, CustomToFixed, checkHasTaxEnable, checkIfUserCanAddBankAccount } from "utils";
import { TenantService } from "services/tenant/index.service";
import { PaymentValueRender } from "components/Payment/PaymentRenderItems";
import { PaymentItemRender } from "components/Payment/PaymentRenderItems";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import withNotification from "components/Hoc/withNotification";
import MemberSelection from "components/submemberseletion/MemberSelection";
import withClientSelection from "components/Hoc/withClientSelection";
import { useLocation } from "react-router-dom";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CreditsErrorMessage } from "utils/form-utils";
import ClientNameWithMemberType from "../ClientNameWithMemberType";

interface IOneTimeCharge {
  UserMemberId: any;
  Description: any;
  Amount: any;
  PaymentGatewayPaymentProfileId: any;
  IsTaxSkipped:boolean;
  CanCollectTaxes:boolean;
}

const schema = Yup.object({
  UserMemberId: Yup.string().required("Client is required"),
  Description: Yup.string().required("Description is required"),
  Amount: Yup.number()
    .min(1, "Amount Should be greater than 0")
    .required("Amount is required"),
  PaymentGatewayPaymentProfileId: Yup.string().required(
    "Payment Profile is required"
  ),
});

const OneTimeCharge = (props: any) => {
  const paymentDropdownItems = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible: true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add Bank Account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount(),
    },
  ];
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  const [Taxes, setTaxes] = useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [confirmClientDialog, setConfirmClientDialog] = useState(false);
  const [tempFormData, setTempFormData] = useState<any>(null);

  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    React.useState<any>(undefined);
  const service = new ClassService();
  const fetchUserSession = async () => {
    await service.getTenantDetails(
      props.from ? props?.uuid : props?.match?.params?.uuid
    );
  };
  const location = useLocation();
  useEffect(() => {
    fetchAllApis();
  }, [location?.key]);

  const fetchUserDetails = async (userMemberId: any) => {
    setLoading(true);
    const tenantService = new TenantService();
    const res = await tenantService?.getUserInfo(userMemberId);
    if (res) {
      formik.setFieldValue("UserMemberId", res.UserMemberId, false);
      setUserDetails(res);
      fetchPaymentProfiles(res.ParentId || res.UserMemberId);
    } else {
      setUserDetails(null);
      props?.handleNotificationMessage(`User not found`, "error");
    }
    setLoading(false);
  };
  const getAllSubMembers = async (memberId: any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    setAllClientsList(res);
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService= new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setTaxes(res);
  };

  
  const handleTaxCalculation=(purchaseAmt:any,TaxItems=Taxes)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
        return acc + currentValue?.Amount
    }, 0);
    setTaxes(TaxesArray)
    setTotalTaxAmount(taxAmt)
  }

  const fetchAllApis = async () => {
    
    if (props?.UserMemberId) {
      setLoading(true);
      setUserDetails(props?.userDetails);
      formik.setFieldValue("UserMemberId", props.UserMemberId, false);
      await getAllSubMembers(props?.UserMemberId);
      await fetchPaymentProfiles(props?.UserMemberId);
      await fetchTaxes();
      setLoading(false);
    } else if(props?.match?.params?.userId){
      setLoading(true);
      await fetchUserSession();
      await fetchTaxes();
      setLoading(false);
      fetchUserDetails(props?.match?.params?.userId);
    }else {
      setLoading(true);
      await fetchUserSession();
      await fetchTaxes();
      setLoading(false);
      props?.handleClientPopup(
        `/app/oneTimeCharge/${props?.match?.params?.uuid}/`,
        null,undefined,"One Time Charge"
      );
    }
  };
  const handleReset = () => {
    formik.resetForm();
    setSelectedPaymentProfile(undefined);
    setTotalTaxAmount(0)
    formik.setFieldValue("UserMemberId", props?.match?.params?.userId || props.UserMemberId, false);    
  };
  const handleSubmit = async (values: IOneTimeCharge) => {
    //api call
    if((selectedPaymentProfile?.CardTypeId===8 && values.Amount > selectedPaymentProfile?.Credit)) {
      props?.handleNotificationMessage(CreditsErrorMessage,'error')
      return 
    }

    if (AllClientsList?.length > 1 ){
      setTempFormData(values)
      toggleClientConfirmDialog() 
      return;
    }
    setTempFormData(null)
    apiCall(values)

  };

  const apiCall =  async (values: IOneTimeCharge) =>  {
    setConfirmClientDialog(false)
    setBtnLoading(true);
    const service = new TenantService();
    const res = await service.SubmitOnDemandPayment(values);
    setBtnLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(`Processed Successfully`, "success");
      handleReset();
      await fetchAllApis()
    } else {
      props?.handleNotificationMessage(
        res.ErrorMessages?.[0] || `Error occured while processing`,
        "error"
      );
    }    
  }
  
  const formik = useFormik({
    initialValues: {
      UserMemberId: "",
      Description: "",
      Amount: 0,
      PaymentGatewayPaymentProfileId: "",
      IsTaxSkipped: true
    } as IOneTimeCharge,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      let submitValues={...values,CanCollectTaxes: !values.IsTaxSkipped}
      handleSubmit(submitValues);
    },
  });

  async function fetchPaymentProfiles(userMemberId: any) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      setPaymentProfile(result);
    }
  }
  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };
  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile(val);
  };
  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const toggleClientConfirmDialog = () => {
    setConfirmClientDialog(!confirmClientDialog);
  };

  
  const renderClientConfirmationDialog = () => {
    const selectedClientInfo=AllClientsList?.filter(
      (res: any) => res?.UserMemberId === userDetails?.UserMemberId
    )[0]
    return (
      <Dialog
        className="bz-book-Appoinment-dialog"
        title={"Please Confirm"}
        onClose={() => toggleClientConfirmDialog()}
        width={"35%"}
      >
        <div>
          <p className={"dialogContainer"}>
            {`Looks like you have additional family members on account, please confirm you are making purchases for the below client.`}
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <ClientNameWithMemberType
              clientName={selectedClientInfo?.FullName}
              memberType={selectedClientInfo?.MemberType}
            />
          </div>
          <div className="column-button-section book-appt-btns">
          <Button primary={false} onClick={() => toggleClientConfirmDialog()}>
              No
            </Button>
            <BuzopsButton
              label={"Yes"}
              onClick={() =>
                apiCall(tempFormData)
              }
            />
          </div>
        </div>
      </Dialog>
    );
  };


  const handleSuccessClosePaymethodDialog = async (msg = null) => {
    if(msg){
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog);
    fetchPaymentProfiles(userDetails?.ParentId || userDetails?.UserMemberId);
  };
  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${
            checkIfUserCanAddBankAccount() ? "Add Bank or Card" : "Add Card"
          }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={userDetails?.ParentId || userDetails?.UserMemberId}
          />
        </Dialog>
      </>
    );
  };
  const updateSelectedClientData = (dataItem: any) => {
    setUserDetails(dataItem);
    formik.setFieldValue("UserMemberId", dataItem.UserMemberId);
  };
  if (loading) {
    return <BuzopsLoader />;
  }

  const loadClientSection = () => {
    if (props?.UserMemberId) {
      return (
        <MemberSelection
          allClientsList={AllClientsList}
          selectedUsermemberId={userDetails?.UserMemberId}
          handleUpdateClient={(val: any) => updateSelectedClientData(val)}
          width={"280px"}
        />
      );
    } else if (props?.match?.params?.userId) {
      if (userDetails) {
        return (
          <span style={{fontFamily: 'Times New Roman'}}>
            {userDetails?.User?.FullName}
          </span>
        );
      } else {
        return (
          <strong className="text-danger">
            <i>User Not Found</i>
          </strong>
        );
      }
    } else {
      return (
        <ClientSelect
          setSelectedClient={(value: any) => {
            setUserDetails({
              ...value,
              ParentId: value?.MainMemberId || value?.UserMemberId,
            });
            fetchPaymentProfiles(value?.MainMemberId || value?.UserMemberId);
            formik.setFieldValue("UserMemberId", value?.UserMemberId);
          }}
          reset={resetForm}
          setResetForm={() => setResetForm(false)}
        />
      );
    }
  };
  return (
    <>
      <h5 className="d-flex justify-content-between mb-1 bz_fs_1_5">
        One Time Charge
      </h5>
      <div className="text-muted">
        Amount charged for a service that is not normally included as a part of
        the subscription model.
      </div>
      <hr />
      <div className="bz-subsection-row one-time-charge">
        <div className="left-section">
          <div className="bz-mt-2rem bz-mb-2_5rem">
            <form onSubmit={formik.handleSubmit}>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Client</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <div className={"k-form-field-wrap"}>
                    {loadClientSection()}
                    {formik.errors.UserMemberId && (
                      <Error>
                        <span>{formik.errors.UserMemberId}</span>
                      </Error>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`bz-content-text-row ${
                  formik.errors.Description ? "align-items-start" : ""
                }`}
              >
                <div className="bz-content-title text-right">Description</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <div className={"k-form-field-wrap"}>
                    <Input
                      onChange={(event) =>
                        formik.setFieldValue(
                          "Description",
                          event?.target?.value
                        )
                      }
                      value={formik.values.Description}
                      type={"text"}
                      style={{ width: "280px" }}
                      id={"Description"}
                    />
                    {formik.errors.Description && (
                      <Error>
                        <span>{formik.errors.Description}</span>
                      </Error>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`bz-content-text-row ${
                  formik.errors.Amount ? "align-items-start" : ""
                }`}
              >
                <div className="bz-content-title text-right">Amount</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <div className={"k-form-field-wrap"}>
                    <NumericTextBox
                      width={280}
                      defaultValue={0}
                      format="c2"
                      min={0}
                      onChange={(event) =>{
                        formik.setFieldValue("Amount", event?.target?.value)
                        handleTaxCalculation(event?.target?.value)
                      }
                      }
                      value={formik.values.Amount}
                    />
                    {formik.errors.Amount && (
                      <Error>
                        <span>{formik.errors.Amount}</span>
                      </Error>
                    )}
                  </div>
                </div>
              </div>
              {checkHasTaxEnable() && (<>
              <div
                className={`bz-content-text-row`}
              >
                  <div className="bz-content-title text-right">Surcharges</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <div className={"k-form-field-wrap"}>
                    <Switch
                  checked={!formik.values.IsTaxSkipped}
                  defaultChecked={false}
                  name={`IsTaxSkipped`}
                  onChange={(e: any) =>
                    formik.setFieldValue("IsTaxSkipped", !formik.values.IsTaxSkipped)
                  }
                  className="mr-1 d-inline-flex"
                />
                    </div>
                  </div>
                </div>
              </>)}
              {!formik.values.IsTaxSkipped && TotalTaxAmount > 0 && (<>
              <div
                className={`bz-content-text-row`}
              >
                <div className="bz-content-title text-right"> <Tooltip
                content={(tooltipProps: any) => {
                  return (
                    <TooltipContentPackageService
                      title={tooltipProps.title}
                      data={Taxes}
                      TotalTaxAmount={TotalTaxAmount}
                    />
                  );
                }}
                tooltipClassName="bz-tooltip-table"
              >
                <a
                  title="Surcharges"
                  style={{
                    borderBottom: "1px dotted #0d6efd",
                    cursor: "pointer",
                  }}
                >
                  {"Surcharges to be collected"}
                </a>
              </Tooltip></div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {CurrencyFormat(TotalTaxAmount)}
                </div>
              </div>
              <div
                className={`bz-content-text-row`}
              >
                <div className="bz-content-title text-right">Total</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {CurrencyFormat(formik.values.Amount + TotalTaxAmount)}
                </div>
              </div>
              </>)}
              <div
                className={`bz-content-text-row ${
                  formik.errors.PaymentGatewayPaymentProfileId
                    ? "align-items-start"
                    : ""
                }`}
              >
                <div className="bz-content-title text-right">
                  Payment Profile
                </div>
                <span className="bz-colon text-center">:</span>

                <div className="bz-content-body payment-profile">
                  <div>
                    <DropDownList
                      id={"selectedPaymentProfile"}
                      name={"selectedPaymentProfile"}
                      disabled={!formik.values.UserMemberId}
                      data={paymentProfile}
                      listNoDataRender={() => "No Payment Methods"}
                      textField="MaskedCCNumber"
                      dataItemKey="PaymentProfileID"
                      valueRender={(
                        el: React.ReactElement<HTMLSpanElement>,
                        value: any
                      ) =>
                        PaymentValueRender(el, value, selectedPaymentProfile)
                      }
                      itemRender={PaymentItemRender}
                      key={`${selectedPaymentProfile?.PaymentGatewayPaymentProfileID}`}
                      onChange={(e) => {
                        handleChangeSavedCard(e.value);
                        formik.setFieldValue(
                          "PaymentGatewayPaymentProfileId",
                          e.value.PaymentGatewayPaymentProfileID
                        );
                      }}
                      style={{ width: "280px" }}
                    />
                    {formik.errors.PaymentGatewayPaymentProfileId && (
                      <Error>
                        <span>
                          {formik.errors.PaymentGatewayPaymentProfileId}
                        </span>
                      </Error>
                    )}
                  </div>
                <div className="addNewBtn">
                  {console.log(paymentDropdownItems)}
                      <DropDownButton
                        text="Add New"
                        disabled={!formik.values.UserMemberId}
                        onItemClick={(e: any) => onCardOrBankAdd(e)}
                        items={paymentDropdownItems.filter(
                          (i: any) => i?.visible === true
                        )}
                        look="flat"
                      className="btn btn-link add-new-btn"
                      />
                    </div>
                </div>
              </div>
              <div className="bz-content-text-row mt-4 pt-2">
                <div className="bz-content-title text-right"></div>
                <span className="bz-colon text-center"></span>
                <div className="bz-content-body">
                  <Button
                    className="ml-0"
                    disabled={!formik.dirty || btnLoading}
                    type="button"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </Button>
                  <BuzopsButton
                    type="submit"
                    label="Save"
                    className="btn "
                    disabled={!formik.dirty || btnLoading}
                    loading={btnLoading}
                  />
                </div>
              </div>
            </form>
          </div>
          {showPaymentDialog && PaymethodDialog()}
          {confirmClientDialog && renderClientConfirmationDialog()}

        </div>
      </div>
    </>
  );
};

export default withClientSelection(withNotification(OneTimeCharge));
